<script lang="ts">
	import PodcastCard from "./PodcastCard.svelte";
	import podcast from "../assets/images/podcast.png?webp&h=408&imagetools";
	import type { ActionReturn } from "svelte/action";
	import * as Glide from "@glidejs/glide";
	import { disableGlidePerSlideAmount } from "../utils/disableGlidePerSlideAmount.js";
	import Paginator from "./Paginator.svelte";
	import { slidesForCyclicGlider } from "./slidesForCyclicGlider.js";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import type { Podcast } from "../../../core/schema/Podcast.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { scrollIntoView } from "../utils/scrollIntoView.js";
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let podcasts: Podcast[];
	export let loading: "lazy" | "eager" = "lazy";

	const { TENANT_LOCALE } = getGlobalSettings();

	const lgBreakpoint = 1325;
	const mdBreakpoint = 900;

	const podcastsToShow = 3;
	const podcastsToShowMd = 2;
	const podcastsToShowSm = 1;

	const podcastsPerView = typeof window !== "undefined" ? (window.innerWidth > lgBreakpoint ? 3 : 1) : 1;

	let currentSlide = 1;
	let visibleSlides: number[] =
		typeof window !== "undefined" ? (window.innerWidth > lgBreakpoint ? [1, 2, 3] : [1]) : [];
	let shouldScroll = false;

	function glide(element: HTMLDivElement): ActionReturn {
		const currentPodcastName = new URLSearchParams(window.location.search).get("podcast");
		const currentPodcastIndex =
			currentPodcastName === null
				? NaN
				: podcasts.findIndex((podcast) => podcast.name[TENANT_LOCALE] === currentPodcastName);

		const currentPodcast = currentPodcastIndex === -1 ? NaN : currentPodcastIndex;

		const startAt = isNaN(currentPodcast) ? (podcastsPerView === 1 ? 0 : 1) : currentPodcast;
		currentSlide = startAt;

		if (!isNaN(currentPodcast)) {
			shouldScroll = true;
			const arraySize = window.innerWidth > lgBreakpoint ? 3 : 1;
			visibleSlides =
				window.innerWidth > lgBreakpoint
					? Array.from({ length: arraySize }, (_, i) => currentSlide - 1 + i)
					: [currentSlide + 1];
		}

		const glideOptions = {
			type: "slider",
			startAt,
			focusAt: window.innerWidth < lgBreakpoint && window.innerWidth > mdBreakpoint ? 1 : "center",
			perView:
				window.innerWidth < mdBreakpoint
					? podcastsToShowSm
					: window.innerWidth < lgBreakpoint
						? podcastsToShowMd
						: podcastsToShow,
			autoplay: false,
			hoverpause: false,
			animationDuration: 650,
			animationTimingFunc: "ease-in-out",
			keyboard: true,
			rewind: true,
			gap: 0,
		} satisfies Partial<Glide.Options>;

		// eslint-disable-next-line new-cap
		const glide = new Glide.default(element, glideOptions);
		glide.on("run", () => {
			currentSlide = glide.index;

			const { perView } = glide.settings;
			visibleSlides = perView === 3 ? Array.from({ length: perView }, (_, i) => currentSlide + i) : [currentSlide + 1];
		});
		glide.on("resize", () => {
			currentSlide = glide.index;

			disableGlidePerSlideAmount(glide, element);
			if (window.innerWidth < mdBreakpoint) {
				glide.update({ perView: podcastsToShowSm, focusAt: "center" });
			} else if (window.innerWidth < lgBreakpoint) {
				glide.update({ perView: podcastsToShowMd, focusAt: 1 });
			} else {
				glide.update({ perView: podcastsToShow, focusAt: "center" });
			}

			const { perView } = glide.settings;
			visibleSlides = perView === 3 ? Array.from({ length: perView }, (_, i) => currentSlide + i) : [currentSlide + 1];
		});
		glide.on("mount.after", () => {
			const [serialNumber] = window.location.hash.slice(1).split("-").map(Number);
			const foundPodcast = podcasts.find((podcast: Podcast) => podcast.serialNumber === serialNumber);
			const podcastIndex = foundPodcast ? podcasts.indexOf(foundPodcast) : -1;
			if (podcastIndex !== -1 && podcastIndex !== currentSlide) {
				glide.go(`=${podcastIndex}`);
			}
		});
		glide.mount();

		return {
			destroy(): void {
				glide.destroy();
			},
		};
	}
</script>

<div class="flex w-full flex-col items-center">
	<span
		class="text-primary m-0 text-center text-[1.375rem] font-bold uppercase tracking-[0.3em] md:text-[2rem] lg:mb-16"
		>{nbspify("Náš podcast")}</span
	>
	<div class="flex w-full flex-col justify-center gap-x-[5.938rem] gap-y-4 lg:flex-row">
		<div class="flex flex-col">
			<h1
				class="text-primary my-6 pt-0 text-center text-xl uppercase leading-[1.25] tracking-[0.3em] md:text-2xl lg:mt-4 lg:text-left lg:text-[4rem] lg:leading-[1.1]"
			>
				Kruté
				<br />
				<span class="font-bold">sklady</span>
			</h1>
			<p class="text-center text-xs text-gray-400 lg:max-w-[22.813rem] lg:text-left">
				<span class="font-semibold">
					{nbspify(
						"Jeden má rád ošoupaná trika, džíny, tenisky a kšiltovky. Druhý nosí košile s vlastním monogramem, stylová saka a elegantní doplňky. Jeden je bajker, druhý hodinkový mág.",
					)}
				</span>
				{nbspify(
					"Managing Director Michal a Head of Sales Martin pracují spolu v BITO a dokonale se doplňují. V tomto podcastu vám přinášejí své příběhy, zkušenosti a taky zajímavé lidi ze světa byznysu. Svérázným a nepřikrášleným způsobem.",
				)}
			</p>
		</div>
		<StaticPicture
			{loading}
			image={podcast}
			alt="Michal a Martin"
			width={670}
			height={204}
			imgClass="h-[12.75rem] md:h-[25.5rem] w-full object-contain md:object-cover xl:w-[41.875rem]"
		/>
	</div>

	<div class="flex w-full flex-col" use:glide use:scrollIntoView={shouldScroll}>
		<div
			class="glide__track border-gray overflow-hidden {currentSlide !== 0 &&
				currentSlide !== podcasts.length - 1 &&
				'lg:border-x'}"
			data-glide-el="track"
		>
			<div class="glide__slides flex">
				{#each podcasts as podcast, index}
					<div class="glide__slide">
						<PodcastCard
							{podcast}
							first={visibleSlides.length === 3
								? slidesForCyclicGlider(visibleSlides, podcasts.length)[0] === index + 1
								: slidesForCyclicGlider(visibleSlides, podcasts.length)[1] === index + 1}
						/>
					</div>
				{/each}
			</div>
		</div>
		<Paginator
			class="w-full border-t-0 md:mx-auto md:w-2/3 lg:w-[33.5%] lg:pl-[.125rem]"
			{visibleSlides}
			{currentSlide}
			itemsLength={podcasts.length}
			customBulletCount={podcastsPerView}
		/>
	</div>
</div>
